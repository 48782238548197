<template>
  <section class="invoice-preview-wrapper">
    <b-row class="invoice-add">

      <!-- Col: Left (Invoice Container) -->
      <b-col
        cols="12"
        xl="9"
        md="8"
      >
        <b-card
          class="invoice-preview-card"
          no-body
        >
          <b-card-body>
            <!-- Estado de la cotización -->
            <b-row>
              <b-col
                cols="12"
                md="11"
              >
                <h3>
                  <b-badge
                    :variant="getStatusVariant(customerQuoteData.Estado)"
                    pill
                  >
                    #{{ customerQuoteData.Id }} - {{ customerQuoteData.Estado }}
                  </b-badge>
                </h3>
              </b-col>

              <b-col
                v-if="customerQuoteData.Estado === 'En Proceso' || customerQuoteData.Estado === 'Correcciones'"
                cols="12"
                md="1"
                right
              >
                <!-- Pencil feather icon -->
                <feather-icon
                  icon="Edit2Icon"
                  size="24"
                  class="cursor-pointer"
                  @click="$emit('addEditTab')"
                />
              </b-col>
            </b-row>

            <!-- Info de cliente -->
            <b-row class="mt-1">
              <b-col
                cols="12"
                xl="12"
                md="12"
              >
                <h3>#{{ customerQuoteData.CustomerInfo.identidades }} - {{ customerQuoteData.CustomerInfo.nombre }}</h3>
              </b-col>
            </b-row>

            <b-row class="mt-1">
              <b-col
                cols="12"
                xl="6"
                md="6"
              >
                <p
                  style="font-size: 13.5px;"
                >
                  <!-- NIT -->
                  <span class="font-weight-bold">NIT:</span>
                  {{ (customerQuoteData.CustomerInfo.nit_cf === 'Y') ? `Consumidor final - ${customerQuoteData.CustomerInfo.nit}` : customerQuoteData.CustomerInfo.nit }}
                  <br>
                  <!-- Dirección -->
                  <span class="font-weight-bold">Dirección:</span>
                  {{ (customerQuoteData.CustomerInfo.direccion) ? customerQuoteData.CustomerInfo.direccion : '' }}
                  <br>
                  <!-- Departamento -->
                  <span class="font-weight-bold">Departamento:</span>
                  {{ (customerQuoteData.CustomerInfo.depto) ? customerQuoteData.CustomerInfo.depto : '' }}
                  <br>
                  <!-- Municipio -->
                  <span class="font-weight-bold">Municipio:</span>
                  {{ (customerQuoteData.CustomerInfo.muni) ? customerQuoteData.CustomerInfo.muni : '' }}
                  <br>
                  <!-- Contacto -->
                  <span class="font-weight-bold">Contacto:</span>
                  {{ (customerQuoteData.CustomerInfo.contacto) ? customerQuoteData.CustomerInfo.contacto : '' }}
                  <br>
                  <!-- Teléfono -->
                  <span class="font-weight-bold">Teléfono:</span>
                  {{ (customerQuoteData.CustomerInfo.tel) ? customerQuoteData.CustomerInfo.tel : '' }}
                  <br>
                  <!-- Celular -->
                  <span class="font-weight-bold">Celular:</span>
                  {{ (customerQuoteData.CustomerInfo.movil) ? customerQuoteData.CustomerInfo.movil : '' }}
                  <br>
                  <!-- Dias de credito -->
                  <span class="font-weight-bold">Días de crédito:</span>
                  {{ (customerQuoteData.CustomerInfo.dias_credito) ? customerQuoteData.CustomerInfo.dias_credito : '' }}
                  <br>
                  <!-- Tipo -->
                  <span class="font-weight-bold">Tipo de cliente:</span>
                  {{ (customerQuoteData.CustomerInfo.tipo_desc) ? customerQuoteData.CustomerInfo.tipo_desc : '' }}
                  <br>
                  <!-- Lista de precios -->
                  <span class="font-weight-bold">Lista de precios:</span>
                  {{ (customerQuoteData.CustomerInfo.listap_desc) ? customerQuoteData.CustomerInfo.listap_desc : '' }}
                </p>
              </b-col>
              <b-col
                cols="12"
                xl="6"
                md="6"
              >
                <p
                  style="font-size: 13.5px;"
                >
                  <!-- Ingresado -->
                  <span class="font-weight-bold">Colocada por:</span>
                  {{ customerQuoteData.UsuarioNombre }} <b>el:</b> {{ customerQuoteData.FechaIngresoSistema }}
                  <br>
                  <span class="font-weight-bold">Fecha de la cotización:</span>
                  {{ customerQuoteData.FechaCotizacionCliente }}
                  <br>
                  <!-- Validez -->
                  <span class="font-weight-bold">Válida hasta:</span>
                  {{ customerQuoteData.FechaVencimiento }}
                  <br>
                  <!-- Logotipo -->
                  <span class="font-weight-bold">Logotipo:</span>
                  {{ (customerQuoteData.Logotipo) === 'K' ? 'Kultiver' : 'Kultiver y Potenz' }}
                  <br>
                  <!-- Flete -->
                  <span class="font-weight-bold">¿Incluye flete?</span>
                  {{ (customerQuoteData.Flete) === 'Y' ? 'Si' : 'No' }}
                  <br>
                  <!-- Flete -->
                  <span class="font-weight-bold">¿Incluye IVA?</span>
                  {{ (customerQuoteData.IVA) === 'Y' ? 'Si' : 'No' }}
                  <br>
                </p>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col
                cols="12"
                xl="12"
                md="12"
              >
                <!-- Observaciones -->
                <span class="font-weight-bold">Observaciones de cliente:</span>
                {{ (customerQuoteData.CustomerInfo.observaciones) ? customerQuoteData.CustomerInfo.observaciones : '' }}
                <br>
                <span class="font-weight-bold">Observaciones la cotización:</span>
                {{ (customerQuoteData.Observaciones) ? customerQuoteData.Observaciones : 'No hay observaciones' }}
              </b-col>
            </b-row>
          </b-card-body>

          <!-- Detalle de la cotización -->
          <b-card-body class="invoice-padding form-item-section">
            <b-table
              responsive
              :items="customerQuoteData.Detalle"
              :fields="CustomerQuoteFields"
            >
              <template #cell(precio_cotizacion)="data">
                Q.{{ data.item.precio_cotizacion }}
              </template>
              <template #cell(total)="data">
                Q.{{ data.item.total }}
              </template>
            </b-table>
            <div
              v-if="customerQuoteData.SummaryInfo[0]"
              class="float-right mr-4"
            >
              Total:
              <h4 _class="sale-total-amount">
                Q.{{ customerQuoteData.SummaryInfo[0].total }}
              </h4>
            </div>
          </b-card-body>
        </b-card>

        <b-card
          class="invoice-preview-card"
          no-body
        >
          <b-card-body>
            <!-- Estado de la cotización -->
            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <h4
                  v-if="(customerQuoteData.Comentarios.length > 0)"
                  class="font-weight-bold mt-2"
                >
                  Comentarios:
                </h4>

                <div
                  v-for="(comment, index) in customerQuoteData.Comentarios"
                  :key="index"
                  class="mt-1"
                >
                  <!-- Si el comentario es tipo = user -->
                  <p v-if="comment.tipo === 'user'">
                    <span class="font-weight-bold">
                      {{ comment.usuario }}
                    </span>
                    <span class="float-right font-italic">
                      {{ comment.fecha }}
                    </span>
                    <br>
                    {{ comment.comentario }}
                  </p>

                  <p v-if="comment.tipo === 'system'">
                    <span class="text-info">
                      <b>{{ comment.usuario }}</b> realizó una acción:
                    </span>
                    <span class="float-right font-italic text-info">
                      {{ comment.fecha }}
                    </span>
                    <br>
                    <span class="text-info">
                      {{ comment.comentario }}
                    </span>
                  </p>

                  <!-- Spacer -->
                  <hr class="invoice-spacing">
                </div>

                <!-- Textarea para agregar comentarios -->
                <b-form-group
                  class="mb-0"
                >
                  <b-form-textarea
                    id="commentText"
                    v-model="commentText"
                    placeholder="Agregar comentario"
                  />

                  <!-- boton alineado a la derecha -->
                  <b-button
                    variant="primary"
                    :disabled="commentText.length === 0 || disabledAction === 'disabled'"
                    class="float-right mt-1"
                    @click="addComment"
                  >
                    Comentar
                  </b-button>
                </b-form-group>

              </b-col>
            </b-row>
          </b-card-body>
        </b-card>
      </b-col>

      <!-- Right Col: Card -->
      <b-col
        v-if="customerQuoteData.Estado === 'En Proceso' || customerQuoteData.Estado === 'Enviado' || customerQuoteData.Estado === 'Correcciones' || customerQuoteData.Estado === 'Aceptado'"
        cols="12"
        md="4"
        xl="3"
        class="invoice-actions mt-md-0 mt-2"
      >
        <!-- Action Buttons -->
        <b-card>
          <!-- Button: Mostrar PDF de la cotización -->
          <b-button
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-secondary"
            block
            @click="generarPDF()"
          >
            Mostrar PDF
          </b-button>
          <!-- Button: Enviar -->
          <b-button
            v-if="customerQuoteData.Estado === 'En Proceso'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-secondary"
            block
            @click="changeStatus('Enviado')"
          >
            Enviar a cliente
          </b-button>

          <b-button
            v-if="customerQuoteData.Estado === 'Enviado'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-success"
            block
            @click="changeStatus('Aceptado')"
          >
            Aceptar
          </b-button>
          <b-button
            v-if="customerQuoteData.Estado === 'Enviado'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-danger"
            block
            @click="changeStatus('Declinado')"
          >
            Declinar
          </b-button>

          <b-button
            v-if="customerQuoteData.Estado === 'En Proceso' || customerQuoteData.Estado === 'Enviado'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-warning"
            block
            @click="changeStatus('Correcciones')"
          >
            Corregir
          </b-button>

          <b-button
            v-if="customerQuoteData.Estado === 'Aceptado'"
            v-ripple.400="'rgba(255, 159, 67, 0.15)'"
            variant="outline-dark"
            block
            @click="changeStatus('Pedido')"
          >
            Generar pedido
          </b-button>

        </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import axios from '@axios'
import Ripple from 'vue-ripple-directive'
import JSPDF from 'jspdf'
import logoKultiver from '@/assets/images/logo/LogoKultiver.jpg'
import logoPotenz from '@/assets/images/logo/LogoPotenz.jpg'

export default {

  directives: {
    Ripple,
  },

  props: {
    quoteId: {
      type: Number,
      default: () => 0,
    },
  },

  data() {
    return {
      customerQuoteData: {
        Id: '',
        Estado: '',
        Logotipo: '',
        Flete: '',
        IVA: '',
        FechaCotizacionCliente: '',
        FechaIngresoSistema: '',
        FechaVencimiento: '',
        UsuarioNombre: '',
        SummaryInfo: [],
        Detalle: [],
        Comentarios: [],
        Observaciones: '',
        Vendedor: '',
        VendedorEmail: '',
        CustomerInfo: [],
      },

      CustomerQuoteFields: [
        { key: 'producto', label: 'Producto' },
        { key: 'producto_desc', label: 'Descripción' },
        { key: 'cantidad', label: 'Cantidad' },
        { key: 'precio_cotizacion', label: 'Precio' },
        { key: 'total', variant: 'success' },
      ],

      anulated: false,
      showPrint: false,

      commentText: '',
      disabledAction: 'enabled',
    }
  },

  mounted() {
    if (this.quoteId > 0) {
      this.getCustomerQuoteData()
    }
  },

  methods: {
    generarPDF() {
      // Crear una instancia de jsPDF
      const doc = new JSPDF('p', 'mm', 'letter')

      const nitCliente = (this.customerQuoteData.CustomerInfo.nit_cf === 'Y') ? `Consumidor final - ${this.customerQuoteData.CustomerInfo.nit}` : this.customerQuoteData.CustomerInfo.nit
      let correo = (this.customerQuoteData.CustomerInfo.email_2) ? this.customerQuoteData.CustomerInfo.email_2 : ''
      correo += (this.customerQuoteData.CustomerInfo.email_3) ? ` / ${this.customerQuoteData.CustomerInfo.email_3}` : ''

      // Logotipos
      if (this.customerQuoteData.Logotipo === 'K') {
        doc.addImage(logoKultiver, 'JPEG', 10, 5, 30, 30)
      } else {
        doc.addImage(logoKultiver, 'JPEG', 10, 5, 30, 30)
        doc.addImage(logoPotenz, 'JPEG', 165, 6, 25, 25)
      }

      doc.setFontSize(8)

      // Alineado a la izquierda
      doc.text('NIT: 103852530', 10, 45)
      doc.text('Correo: info@kultiver.com', 10, 50)
      doc.text('Teléfonos: 6631-4498 / 5615-8970', 10, 55)
      doc.text('Dirección: Calle Real 17-80 zona 10 SMP, Guatemala. CC Plaza San Diego, 2do nivel oficina 207', 10, 60)

      // Alineado a la derecha
      doc.text(`Cotización No: ${this.customerQuoteData.Id}`, 165, 45)
      doc.text(`Generada el: ${this.customerQuoteData.FechaCotizacionCliente}`, 165, 50)
      doc.text(`Válida hasta: ${this.customerQuoteData.FechaVencimiento}`, 165, 55)

      // Para esta sección color de fondo gris claro
      doc.setFillColor(230, 230, 230)
      doc.rect(10, 70, 195, 45, 'F')

      // Subtitulo
      doc.setFontSize(10)
      doc.setFont('helvetica', 'bold')
      doc.text('Información del cliente', 15, 77)

      // normal
      doc.setFontSize(8)
      doc.setFont('helvetica', 'normal')

      // Alineado a la izquierda
      doc.text(`Código: #${this.customerQuoteData.CustomerInfo.identidades}`, 15, 85)
      doc.text(`Cliente: ${this.customerQuoteData.CustomerInfo.nombre}`, 15, 90)
      doc.text(`NIT: ${nitCliente}`, 15, 95)
      doc.text(`Teléfonos: ${this.customerQuoteData.CustomerInfo.tel} / ${this.customerQuoteData.CustomerInfo.movil}`, 15, 100)
      doc.text(`Correo(s): ${correo}`, 15, 105)
      doc.text(`Dirección: ${this.customerQuoteData.CustomerInfo.direccion}`, 15, 110)

      // Alineado a la derecha
      // doc.text(`Contacto: ${this.customerQuoteData.CustomerInfo.contacto}`, 150, 85)
      doc.text(`Crédito: ${this.customerQuoteData.CustomerInfo.dias_credito} días`, 150, 85)
      doc.text(`Vendedor: ${this.customerQuoteData.Vendedor}`, 150, 90)
      doc.text(`Correo: ${this.customerQuoteData.VendedorEmail}`, 150, 95)

      // Fin de la sección gris
      doc.setFillColor(255, 255, 255)

      // Tabla de productos
      let y = 125 // Posición inicial de la tabla
      if (this.customerQuoteData.Detalle.length > 0) {
        // Encabezado de la tabla
        doc.setFontSize(10)
        doc.setFont('helvetica', 'bold')
        doc.text('Producto', 10, y)
        doc.text('Descripción', 30, y)
        doc.text('Cantidad', 120, y)
        doc.text('Precio', 140, y)
        doc.text('Importe', 170, y)

        y += 2 // Espacio para el encabezado
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(8)

        // Agregar cada producto a la tabla con bordes en todas las celdas
        this.customerQuoteData.Detalle.forEach(producto => {
          // Dibujar bordes alrededor de la celda
          doc.rect(10, y, 20, 5) // Producto
          doc.rect(30, y, 90, 5) // Descripción
          doc.rect(120, y, 20, 5) // Cantidad
          doc.rect(140, y, 30, 5) // Precio
          doc.rect(170, y, 35, 5) // Importe

          // Agregar contenido a las celdas
          doc.text(producto.producto, 11, y + 4) // Producto
          doc.text(producto.producto_desc, 31, y + 4) // Descripción
          doc.text(producto.cantidad, 121, y + 4) // Cantidad
          doc.text(`Q.${producto.precio_cotizacion}`, 141, y + 4) // Precio
          doc.text(`Q.${producto.total}`, 171, y + 4) // Importe

          y += 5 // Espacio entre las filas
        })

        // Total
        // eslint-disable-next-line prefer-destructuring
        const total = this.customerQuoteData.SummaryInfo[0].total
        doc.setFontSize(10)
        // colocar el total en un rectangulo gris
        doc.setFillColor(230, 230, 230)
        doc.rect(150, y + 2, 55, 5, 'F')
        doc.text('Total:', 151, y + 6)
        doc.text(`Q.${total}`, 171, y + 6)
      }

      y += 10 // Espacio entre la tabla y el total

      // Alineado a la izquierda
      if (this.customerQuoteData.Observaciones !== '') {
        doc.setFontSize(10)
        doc.setFont('helvetica', 'bold')
        doc.text('Observaciones:', 10, y + 5)
        doc.setFont('helvetica', 'normal')
        doc.setFontSize(8)
        doc.text(this.customerQuoteData.Observaciones, 10, y + 10)
        y += 10
      }

      // flete e iva
      const flete = (this.customerQuoteData.Flete === 'Y') ? 'La cotización incluye flete.' : 'La cotización no incluye flete.'
      const iva = (this.customerQuoteData.IVA === 'Y') ? 'Los precios incluyen IVA.' : 'Los precios no incluyen IVA.'

      const datosAdicionales = [
        '- Precios sujetos a cambios sin previo aviso, por variación constante de materia prima.',
        '- Tiempo de entrega estimado: Consultar con el vendedor.',
        `- ${iva}`,
        `- ${flete}`,
      ]

      const cuentasBancos = [
        '- Banco Banrural Quetzales N°: 3540026761',
        '- Banco Industrial Quetzales N°: 126-015764-7',
        '- Banco Industrial Dolares N°: 333-015764-7',
      ]

      doc.setFontSize(10)
      doc.setFont('helvetica', 'bold')
      doc.text('Términos y condiciones:', 10, y + 10)

      doc.setFont('helvetica', 'normal')
      doc.setFontSize(8)

      let datosAdicionalesY = y + 15
      datosAdicionales.forEach(dato => {
        doc.text(dato, 10, datosAdicionalesY)
        datosAdicionalesY += 5
      })

      doc.setFontSize(10)
      doc.setFont('helvetica', 'bold')
      doc.text('Depósitos y transferencias en las cuentas monetarias:', 10, datosAdicionalesY + 5)

      doc.setFont('helvetica', 'normal')
      doc.setFontSize(8)
      let cuentasBancosY = datosAdicionalesY + 10
      cuentasBancos.forEach(dato => {
        doc.text(dato, 10, cuentasBancosY)
        cuentasBancosY += 5
      })

      doc.setFont('helvetica', 'bold')
      doc.text('** Las cuentas bancarias están a nombre de Kultiver S.A.', 10, cuentasBancosY + 5)
      doc.text('** Emitir cheque a nombre de Kultiver S.A.', 10, cuentasBancosY + 10)

      // Guardar el PDF como blob
      const blob = doc.output('blob')

      // Enviar el PDF por correo electronico
      // this.sendPDFQuote(blob)

      // Crear un enlace de descarga
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)

      link.download = `Cotizacion #${this.customerQuoteData.Id}.pdf`

      // Simular el clic en el enlace para iniciar la descarga
      link.click()
    },

    getCustomerQuoteData() {
      axios
        .get(`accounting-system/customer-quote/${this.quoteId}`)
        .then(response => {
          this.customerQuoteData.Id = response.data.data.Id
          this.customerQuoteData.Observaciones = (response.data.data.Observaciones) ? response.data.data.Observaciones : '-'
          this.customerQuoteData.Detalle = response.data.data.Detalle
          this.customerQuoteData.SummaryInfo = response.data.data.SummaryInfo
          this.customerQuoteData.FechaIngresoSistema = response.data.data.FechaIngresoSistema
          this.customerQuoteData.FechaCotizacionCliente = response.data.data.FechaCotizacionCliente
          this.customerQuoteData.FechaVencimiento = response.data.data.FechaVencimiento
          this.customerQuoteData.UsuarioNombre = response.data.data.UsuarioNombre
          this.customerQuoteData.Logotipo = response.data.data.Logotipo
          this.customerQuoteData.Flete = response.data.data.Flete
          this.customerQuoteData.IVA = response.data.data.IVA
          this.customerQuoteData.Estado = response.data.data.Estado
          this.customerQuoteData.Comentarios = response.data.data.Comentarios
          this.customerQuoteData.Observaciones = response.data.data.Observaciones
          this.customerQuoteData.Vendedor = response.data.data.UsuarioNombre
          this.customerQuoteData.VendedorEmail = response.data.data.UsuarioEmail

          // Info del cliente
          this.customerQuoteData.CustomerInfo = response.data.data.CustomerInfo

          if (response.data.data.Estado === 'Cancelado') {
            this.anulated = true
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

    getCurrentDateTime() {
      const date = new Date()
      const dateStr = `${(`00${date.getMonth() + 1}`).slice(-2)}/${
        (`00${date.getDate()}`).slice(-2)}/${
        date.getFullYear()} ${
        (`00${date.getHours()}`).slice(-2)}:${
        (`00${date.getMinutes()}`).slice(-2)}:${
        (`00${date.getSeconds()}`).slice(-2)}`

      return dateStr
    },

    getStatusVariant(estado) {
      switch (estado) {
        case 'En Proceso':
          return 'info'
        case 'Aceptado':
          return 'success'
        case 'Declinado':
          return 'danger'
        case 'Correcciones':
          return 'warning'
        case 'Pedido':
          return 'dark'
        default:
          return 'secondary'
      }
    },

    changeStatus(estado) {
      let alertMsg = ''
      let successMsg = ''

      switch (estado) {
        case 'Enviado':
          alertMsg = '¿Está seguro de enviar la cotización al cliente?'
          successMsg = 'Cotización enviada correctamente.'
          break
        case 'Aceptado':
          alertMsg = '¿Está seguro de marcar la cotización como aceptada por el cliente?'
          successMsg = 'Cotizacion marcada como aceptada correctamente.'
          break
        case 'Declinado':
          alertMsg = '¿Está seguro de rechazar la cotización?'
          successMsg = 'Cotizacion declinada correctamente.'
          break
        case 'Correcciones':
          alertMsg = '¿Está seguro de marcar la cotización en proceso correcciones?'
          successMsg = 'Marcada como en proceso de correcciones, correctamente.'
          break
        case 'Pedido':
          alertMsg = '¿Está seguro de generar el pedido basado en esta cotización?'
          successMsg = 'Pedido generado correctamente.'
          break
        default:
          alertMsg = '¿Está seguro de realizar esta acción?'
          successMsg = 'Acción realizada correctamente.'
          break
      }

      this.$bvModal
        .msgBoxConfirm(alertMsg, {
          title: 'Atención',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'SI',
          cancelTitle: 'NO',
          okTitleVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            axios
              .put(`accounting-system/customer-quote/${this.quoteId}/status/${estado}`)
              .then(response => {
                if (response.data.res) {
                  this.makeToast('success', 'Actualizado', successMsg)
                  this.getCustomerQuoteData()
                } else {
                  throw (response)
                }
              })
              .catch(error => {
                this.showErrors(error)
              })
          }
        })
    },

    userData() {
      return JSON.parse(localStorage.getItem('KultiverAuthUser'))
    },

    addComment() {
      this.disabledAction = 'disabled'
      if (this.commentText.length > 0) {
        const comment = {
          idcotizaciones_enc: this.quoteId,
          comentario: this.commentText,
        }

        axios
          .post('accounting-system/customer-quote-comment', comment)
          .then(response => {
            if (response.data.res) {
              this.makeToast('success', 'Comentario agregado', 'Comentario agregado correctamente.')
              this.commentText = ''
              this.disabledAction = 'enabled'
              this.getCustomerQuoteData()
            } else {
              throw (response)
            }
          })
          .catch(error => {
            this.showErrors(error)
          })
      }
    },

    sendPDFQuote(pdfBlob) {
      const formData = new FormData()
      formData.append('pdf', pdfBlob)

      axios
        .post('accounting-system/send-quote-pdf', formData)
        .then(response => {
          if (response.data.res) {
            this.makeToast('success', 'Enviado', 'PDF enviado correctamente.')
          } else {
            throw (response)
          }
        })
        .catch(error => {
          this.showErrors(error)
        })
    },

  },
}
</script>

<style lang="scss">

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.producto,
th.producto {
    width: 145px;
    max-width: 145px;
}

td.cantidad,
th.cantidad {
    width: 110px;
    max-width: 110px;
    word-break: break-all;
}

td.precio,
th.precio {
    width: 70px;
    max-width: 70px;
    word-break: break-all;
}

.centrado {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 240px;
    max-width: 240px;
    font-size: 10px;
}

img {
    max-width: inherit;
    width: inherit;
}

.total_size {
  font-size:15px
}
</style>
