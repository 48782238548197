<template>
  <b-tabs
    v-model="tabIndex"
    content-class="mt-1"
  >
    <!-- This tabs content will always be mounted -->
    <b-tab
      title="Historial de cotizaciones"
      lazy
    >
      <customer-quotes-list
        @addQuoteTab="newTab($event)"
      />
    </b-tab>

    <b-tab
      title="Nueva cotización"
      lazy
    >
      <customer-quotes-add
        @addQuoteTab="newTab($event)"
      />
    </b-tab>

    <!-- This tabs content will not be mounted until the tab is shown and will be un-mounted when hidden -->
    <b-tab
      v-for="quote in quotesTabs.filter(quote => quote.type === 'view')"
      :key="quote.id"
      :title="'Cotización #' + quote.id"
      lazy
    >
      <customer-quotes-view
        :quote-id="quote.id"
        @addEditTab="editTab(quote.id)"
      />
    </b-tab>

    <b-tab
      v-for="quote in quotesTabs.filter(quote => quote.type === 'edit')"
      :key="quote.id"
      :title="'Edición cotización #' + quote.id"
      lazy
    >
      <customer-quotes-edit
        v-if="quote.type === 'edit'"
        :quote-id="quote.id"
        @addQuoteTab="newTab($event)"
      />
    </b-tab>

    <!-- Close tab button -->
    <template
      v-if="quotesTabs.length > 0"
      #tabs-end
    >
      <b-nav-item
        @click.prevent="closeTab(quotesTabs[0].id, quotesTabs[0].type)"
      >
        <b>x</b>
      </b-nav-item>
    </template>

  </b-tabs>
</template>

<script>
import CustomerQuotesList from './CustomerQuotesList.vue'
import CustomerQuotesAdd from './CustomerQuotesAdd.vue'
import CustomerQuotesView from './CustomerQuotesView.vue'
import CustomerQuotesEdit from './CustomerQuotesEdit.vue'

export default {
  components: {
    CustomerQuotesList,
    CustomerQuotesAdd,
    CustomerQuotesView,
    CustomerQuotesEdit,
  },

  data() {
    return {
      tabIndex: 0,
      quotesTabs: [],
    }
  },

  methods: {
    newTab(quoteId) {
      this.quotesTabs = []
      this.quotesTabs.push({
        id: quoteId,
        type: 'view',
      })
      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    editTab(quoteId) {
      this.quotesTabs = []
      this.quotesTabs.push({
        id: quoteId,
        type: 'edit',
      })

      this.closeTab(quoteId, 'view') // Close view tab before opening edit tab

      setTimeout(() => {
        this.tabIndex = 2
      }, 100)
    },

    closeTab(quoteId, type) {
      for (let i = 0; i < this.quotesTabs.length; i += 1) {
        if (this.quotesTabs[i].id === quoteId && this.quotesTabs[i].type === type) {
          this.quotesTabs.splice(i, 1)
        }
      }
      this.tabIndex = 0 // Redirect to list component
    },
  },
}
</script>
